<template>
  <div class="manager-course">
    <xbk-room
      v-if="showXbk"
      class="liveRoom"
      :lessonId="lessonId"
      @finishClass="finishClass"
      :lessonStatus="lessonStatus"
    ></xbk-room>
    <dbk-room
      v-if="showDbk"
      class="liveRoom"
      :lessonId="lessonId"
      @finishClass="finishClass"
      :lessonStatus="lessonStatus"
    ></dbk-room>
    <o2o-room
      v-if="showO2O"
      class="liveRoom"
      :lessonId="lessonId"
      @finishClass="finishClass"
      :lessonStatus="lessonStatus"
    ></o2o-room>
    <div class="inner">
      <div v-show="showVideo" id="player-container" style="width:100%; height:auto;"></div>
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="type-bar">
        <div class="t-list">
          <div class="t-type">课程类型：</div>
          <div
            :class="courseTypeIndex === index ? 't-item-active' : 't-item'"
            v-for="(item, index) in courseType"
            :key="index"
            @click="changeCourseType(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="t-list">
          <div class="t-type">状态:</div>
          <div
            :class="courseStateIndex === index ? 't-item-active' : 't-item'"
            v-for="(item, index) in courseState"
            :key="index"
            @click="changeCourseState(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="t-list">
          <div class="t-type">日期：</div>
          <div
            :class="dayTypeIndex === index ? 't-item-active' : 't-item'"
            v-for="(item, index) in dayType"
            :key="index"
            @click="changeDayState(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="t-list">
          <div class="t-type-40">指定日期：</div>
          <el-date-picker
            class="date-picker"
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="onfirmDate"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
      </div>
      <div class="search-bar mb20 mt20">
        <input
          class="s-input nohighlight"
          style="border:none;"
          type="text"
          placeholder="请输入课程名称"
          v-model.trim="courseName"
          @keyup.enter="onSearchByName"
        />
        <span class="s-lens" @click="onSearchByName"></span>
      </div>
      <div class="data-wrap">
        <div class="c-list" v-if="!showNoData">
          <div class="course-item mb40" v-for="item in courseList" :key="item.id">
            <div class="c-cover" :style="{ backgroundImage: 'url(' + item.coverUrl + ')' }">
              <div class="c-tag">{{ courseConfig[item.courseMode] }}</div>
            </div>
            <div class="c-content">
              <div class="c-name">{{ item.courseName }}</div>
              <span>{{ item.lessonName }}</span>
              <span>授课老师：{{ item.teacherName }}</span>
              <span>上课日期：{{ item.lessonDate }}</span>
              <span>直播时长：{{ item.courseDuration }}</span>
              <div class="c-detail">
                <span>学员数量：{{ item.studentNum }}</span>
                <!--
				<div class="c-btn1" @click="courseWareChange(item)">课件管理</div>
                <div class="c-btn2" @click="showStudentsList(item)">学员详情</div>
				-->
              </div>
			  <div
				v-if="(item.lessonStatus == '0' || item.lessonStatus == '1')"
				class="c-start"
				@click="toLive(item)"
			  >
				<div class="icon"></div>
				<font>开始直播</font>
			  </div>
			  <div
				v-else-if="item.lessonStatus == '2' && item.fileUrl !== null"
				class="c-review"
				@click="toReview(item)"
			  >
				<div class="icon"></div>
				<font>回放</font>
			  </div>
			  <div v-else-if="item.lessonStatus == '2' && item.fileUrl == null" class="c-waitting">
				<div class="icon"></div>
				<font>生成回放中</font>
			  </div>
			  <div v-else="item.lessonStatus == '3' || item.lessonStatus == '4'" class="c-finish">
				<div class="icon"></div>
				<font>已结束</font>
			  </div>
            </div>
          </div>
        </div>
        <div v-else class="no-data">
          <div class="n-image"><span>暂无数据</span></div>
        </div>
      </div>
      <el-dialog
        top="8vh"
        class="dialog-container"
        width="80%"
        title="关联课件"
        :visible.sync="showLink"
        append-to-body
      >
        <div class="container-wrap wrap-column-center">
          <div class="link-row">
            <el-button type="success" class="link-btn" @click="linkCourseWare">批量关联</el-button>
            <el-select
              class="t-select-item"
              v-model="data.firstSubjectId"
              placeholder="请选择一级学科"
              @change="onChooseFirstSub"
            >
              <el-option
                v-for="item in firstSubject"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select
              class="t-select-item"
              v-model="data.secondSubjectId"
              @change="onChooseSecSub"
              placeholder="请选择二级学科"
            >
              <el-option
                v-for="item in secondSubject"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-input
              class="courseInput"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="courseWareName"
            ></el-input>
            <el-button type="primary" @click="searchCourseWare">搜索</el-button>
          </div>
          <el-table
            :data="teacherWareList"
            style="width: 100%"
            stripe
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="name" label="课件名称" width="250"></el-table-column>
            <el-table-column prop="fileType" label="格式" width="100"></el-table-column>
            <el-table-column prop="firstSubjectName" label="一级学科" width="120"></el-table-column>
            <el-table-column
              prop="secondSubjectName"
              label="二级学科"
              width="120"
            ></el-table-column>
            <el-table-column label="状态" width="100">
              <template slot-scope="scope">
                <p v-if="scope.row.status == '0'">转码中</p>
                <p v-if="scope.row.status == '1'">正常</p>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="上传时间" width="200"></el-table-column>
            <el-table-column label="操作" width="160">
              <template slot-scope="scope">
                <el-button size="mini" @click="linkSingle(scope.row)">关联</el-button>
                <!-- <el-button size="mini" type="danger" @click="removeCouseWare(scope.row.id, 0)">
                  删除
                </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <el-dialog
        top="8vh"
        class="dialog-container"
        width="67%"
        title="上传课件"
        :visible.sync="showUpload"
        append-to-body
      >
        <div class="container-wrap wrap-column-center">
          <div class="wrap-row-center">
            <div class="c-first">
              <span class="c-text">一级学科</span>
              <el-select
                class="t-select-item"
                v-model="data.firstSubjectId"
                placeholder="请选择一级学科"
                @change="onChooseFirstSub"
              >
                <el-option
                  v-for="item in firstSubject"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <div class="c-second">
              <span class="c-text">二级学科</span>
              <el-select
                class="t-select-item"
                v-model="data.secondSubjectId"
                placeholder="请选择二级学科"
                @change="onChooseSecSub"
              >
                <el-option
                  v-for="item in secondSubject"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="t-text">文件大小50M以内，格式支持 ppt/pptx 、doc/docx、pdf</div>
          <el-upload
            ref="fileUpload"
            class="c-uploader"
            :action="action"
            :headers="headers"
            :data="data"
            :multiple="false"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :on-success="uploadSuccess"
            :on-exceed="handleExceed"
            :show-file-list="false"
          >
            <div class="c-upload">
              <div class="icon-upload"></div>
              上传课件
            </div>
            <div class="c-block" v-show="block" @click.stop="beforeUploadFiles"></div>
          </el-upload>
        </div>
      </el-dialog>
      <el-dialog
        top="8vh"
        title="课件管理"
        width="80%"
        :visible.sync="showCourseware"
        class="dialog-container"
        append-to-body
      >
        <div class="container-wrap">
          <div class="c-header">
            <div class="c-title">{{ currentCourseName }}的文档</div>
            <div class="c-btn">
              <div class="c-upload" @click="onShowUpload">
                <div class="icon-upload"></div>
                上传课件
              </div>
              <div class="c-link" @click="onShowLink">
                <div class="icon-link"></div>
                关联课件
              </div>
            </div>
          </div>
          <el-table :data="courseWareList" style="width: 100%" stripe>
            <el-table-column prop="wareName" label="课件名称" width="250"></el-table-column>
            <el-table-column prop="fileType" label="格式" width="100"></el-table-column>
            <el-table-column prop="firstSubjectName" label="一级学科" width="120"></el-table-column>
            <el-table-column
              prop="secondSubjectName"
              label="二级学科"
              width="120"
            ></el-table-column>
            <el-table-column label="状态" width="100">
              <template slot-scope="scope">
                <p v-if="scope.row.status == '0'">转码中</p>
                <p v-if="scope.row.status == '1'">正常</p>
              </template>
            </el-table-column>
            <el-table-column prop="uploadTime" label="上传时间" width="200"></el-table-column>
            <el-table-column label="操作" width="160">
              <template slot-scope="scope">
                <!--  <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
                <el-button
                  size="mini"
                  type="danger"
                  @click="removeCouseWare(scope.row.courseWareId, 1)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <el-dialog
        top="8vh"
        :title="this.currentCourseName"
        width="65%"
        :visible.sync="showStudents"
        class="dialog-container"
        append-to-body
      >
        <div class="container-wrap">
          <el-table :data="studentList" style="width: 100%" stripe>
            <el-table-column prop="name" label="姓名" width="120"></el-table-column>
            <el-table-column prop="mobileNo" label="手机号" width="150"></el-table-column>
            <el-table-column prop="nickName" label="用户名" width="150"></el-table-column>
            <el-table-column prop="email" label="邮箱" width="226"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="206"></el-table-column>
          </el-table>
          <el-pagination
            class="c-page"
            prev-text="上一页"
            next-text="下一页"
            background
            layout="prev, pager, next"
            :total="stuTotalPages"
            :page-size.sync="stuPageSize"
            @current-change="stuCurrentChange"
            :current-page.sync="stuPageIndex"
          ></el-pagination>
        </div>
      </el-dialog>
      <el-pagination
        class="page"
        prev-text="上一页"
        next-text="下一页"
        background
        layout="prev, pager, next"
        :total="totalPages"
        :page-size.sync="pageSize"
        @current-change="currentChange"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import xbkRoom from '@/components/live-room/xbk-room.vue';
import dbkRoom from '@/components/live-room/dbk-room.vue';
import o2oRoom from '@/components/live-room/o2o-room.vue';
export default {
  name: '',
  data() {
    return {
      showVideo: false,
      block: true,
      curMode: '',
      showDbk: false,
      showO2O: false,
      showXbk: false,
      lessonId: '',
      courseConfig: {
        gkk: '公开课',
        o2o: '一对一',
        lvb: '直播课',
        vod: '点播课',
        f2f: '面授课'
      },
      courseWareName: '',
      currentCourseID: '',
      firstSubjectIndex: 0,
      secondSubjectIndex: 0,
      firstSubjectId: '',
      secondSubjectId: '',
      firstSubject: [],
      secondSubject: [],
      showNoData: false,
      showUpload: false,
      showLink: false,
      courseMode: '',
      courseName: '',
      day: '',
      endDay: '',
      startDay: '',
      status: '',
      courseList: [],
      action: 'http://api.100niu.com/biz/teacher/course/uploadWare',
      headers: {
        Authorization: 'Bearer ' + this.$.data.get('Token')
      },
      data: {
        firstSubjectId: '',
        secondSubjectId: '',
        courseId: ''
      },
      // 关键字
      keyword: '',
      showCourseware: false,
      showStudents: false,
      stateConfig: {
        '0': '转码中',
        '1': '正常'
      },
      courseStateIndex: 0,
      courseState: [
        { name: '全部', value: '' },
        { name: '未开始', value: '0' },
        { name: '进行中', value: '1' },
        { name: '已结束', value: '2' },
        { name: '学员旷课', value: '3' },
        { name: '老师旷课', value: '4' }
      ],
      dayTypeIndex: 0,
      dayType: [
        { name: '全部', value: '' },
        { name: '今天', value: 'today' },
        { name: '昨天', value: 'yesterday' },
        { name: '明天', value: 'tomorrow' }
      ],
      courseTypeIndex: 0,
      courseType: [
        { name: '全部', mode: '' },
        { name: '直播课程', mode: 'lvb' },
        { name: '面授课程', mode: 'f2f' },
        { name: '一对一课程', mode: 'o2o' },
        { name: '公开课程', mode: 'gkk' }
      ],
      // 指定日期 date 对象
      date: [],
      studentList: [],
      teacherWareList: [],
      courseWareList: [],
      fileList: [],
      // 总条数
      totalPages: 0,
      // 每页条数
      pageSize: 16,
      // 当前页码
      pageIndex: 1,
      // 总条数
      stuTotalPages: 0,
      // 每页条数
      stuPageSize: 16,
      // 当前页码
      stuPageIndex: 1,
      teacherWareIds: [],
      currentLessionID: '',
      currentCourseName: ''
    };
  },
  computed: {},
  created() {
    this.getCourseList();
    this.Move();
  },
  mounted() {},
  watch: {},
  methods: {
    // 停止页面滚动
    stopMove() {
      const m = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', m, { passive: false }); // 禁止页面滑动
    },
    // 开启页面滚动
    Move() {
      const m = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = ''; // 出现滚动条
      document.removeEventListener('touchmove', m, { passive: true });
    },
    beforeUploadFiles() {
      this.$.ui.toast('请选择一级学科和二级学科');
    },
    // 下课
    finishClass() {
      this.showDbk = false;
      this.showO2O = false;
      this.showXbk = false;
      this.Move();
    },
    // 当前页变化时执行
    currentChange(e) {
      this.pageIndex = e;
      this.getCourseList();
      document.body.scrollTop = 0
      // firefox
      document.documentElement.scrollTop = 0
      // safari
      window.pageYOffset = 0
    },
    // 当前页变化时执行
    stuCurrentChange(e) {
      this.stuPageIndex = e;
      this.getCourseStudent();
      document.body.scrollTop = 0
      // firefox
      document.documentElement.scrollTop = 0
      // safari
      window.pageYOffset = 0
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length +
          fileList.length} 个文件`
      );
    },
    // 上传成功
    uploadSuccess(response) {
      // 上传课件后，更新课件状态
      console.log(response);
      if (response.code == 200) {
        this.post('/biz/teacher/course/temp/update/course/ware/status', {}, e => {
          if (e.code == 200) {
            this.$.ui.toast('上传成功');
            setTimeout(() => {
              this.showUpload = false;
              this.onShowLink();
            }, 1000);
          } else {
            this.$.ui.toast(e.msg);
          }
        });
      } else {
        this.$.ui.toast(response.msg);
      }
    },
    // 更改课程类型
    changeCourseType(index) {
      this.pageIndex = 1;
      this.courseTypeIndex = index;
      this.courseMode = this.courseType[index].mode;
      this.getCourseList();
    },
    // 更改课程状态
    changeCourseState(index) {
      this.pageIndex = 1;
      this.courseStateIndex = index;
      this.status = this.courseState[index].value;
      this.getCourseList();
    },
    // 更改日期状态
    changeDayState(index) {
      this.pageIndex = 1;
      // 初始化制定日期
      this.startDay = '';
      this.endDay = '';
      this.date = [];
      this.dayTypeIndex = index;
      this.day = this.dayType[index].value;
      this.getCourseList();
    },
    // 确认日期
    onfirmDate() {
      console.log(this.date);
      this.pageIndex = 1;
      // 初始化日期
      this.dayTypeIndex = 0;
      this.day = '';
      if (this.date) {
        this.startDay = this.date[0];
        this.endDay = this.date[1];
      } else {
        this.startDay = '';
        this.endDay = '';
      }
      this.getCourseList();
    },
    // 获取课程列表
    getCourseList() {
      this.showNoData = false;
      this.post(
        `/biz/teacher/course/courseList?pageNum=${this.pageIndex}&pageSize=${this.pageSize}`,
        {
          courseMode: this.courseMode,
          courseName: this.courseName,
          day: this.day,
          endDay: this.endDay,
          startDay: this.startDay,
          status: this.status
        },
        e => {
          if (e.code == 200) {
            this.courseList = e.rows;
            this.totalPages = e.total;
            if (e.total == 0) {
              this.showNoData = true;
            }
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    onSearchByName() {
      this.pageIndex = 1;
      this.getCourseList();
    },
    // 展示课程学生详情
    showStudentsList(item) {
      this.showStudents = true;
      this.currentCourseID = item.courseId;
      this.currentLessionID = item.lessonId;
      this.getCourseStudent();
    },
    // 展示上传列表
    courseWareChange(item) {
      this.currentCourseName = item.courseName;
      this.currentCourseID = item.courseId;
      this.showCourseware = true;
      this.getCourseWares();
    },
    // 获取课程已关联的课件列表
    getCourseWares() {
      this.get(`/biz/teacher/course/wares/${this.currentCourseID}`, {}, e => {
        if (e.code == 200) {
          this.courseWareList = e.data;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    //课程列表的学员详情
    getCourseStudent() {
      this.post(
        `/biz/teacher/course/studentsInfo?pageNum=${this.stuPageIndex}&pageSize=${
          this.stuPageSize
        }`,
        { courseId: this.currentCourseID, lessonId: this.currentLessionID },
        e => {
          if (e.code == 200) {
            this.studentList = e.rows;
            this.stuTotalPages = e.total;
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 获取一级学科列表
    getFirstSubject() {
      this.get('/biz/subject/list', { pid: '' }, e => {
        if (e.code === 200) {
          // 将一级学科索引归零
          this.firstSubjectIndex = 0;
          this.firstSubject = e.data;
          this.getSecondSubject(this.firstSubject[0].id);
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 获取二级学科列表
    getSecondSubject(pid) {
      this.get('/biz/subject/list', { pid: pid }, e => {
        if (e.code === 200) {
          // 将二级学科索引归零
          this.secondSubjectIndex = 0;
          this.secondSubject = e.data;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 更改一级学科下拉框时
    onChooseFirstSub(e) {
      console.log(e);
      console.log('this.data.firstSubjectId', this.data.firstSubjectId);
      console.log('this.data.secondSubjectId', this.data.secondSubjectId);
      if (this.data.firstSubjectId !== '' && this.data.secondSubjectId !== '') {
        this.block = false;
      } else {
        this.block = true;
      }
      this.getSecondSubject(e);
    },
    onChooseSecSub(e) {
      console.log('this.data.firstSubjectId', this.data.firstSubjectId);
      console.log('this.data.secondSubjectId', this.data.secondSubjectId);
      if (this.data.firstSubjectId !== '' && this.data.secondSubjectId !== '') {
        this.block = false;
      } else {
        this.block = true;
      }
    },
    // 展示上传组件
    onShowUpload() {
      this.showUpload = true;
      this.getFirstSubject();
      this.getTeacherWares();
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const file1 = testmsg === 'ppt';
      const file2 = testmsg === 'pptx';
      const file3 = testmsg === 'doc';
      const file4 = testmsg === 'docx';
      const file5 = testmsg === 'pdf';
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!file1 && !file2 && !file3 && !file4 && !file5) {
        this.$message.error('上传文件格式支持 ppt/pptx 、doc/docx、pdf ！');
      }
      if (!isLt50M) {
        this.$message.error('上传文件大小不能超过 50MB!');
      }
      let isFile = file1 || file2 || file3 || file4 || file5;
      return isFile && isLt50M;
    },
    // 展示关联课件
    onShowLink() {
      this.getFirstSubject();
      this.getTeacherWares();
      this.showLink = true;
    },
    // 取得教师的课件列表
    getTeacherWares() {
      this.post(
        `/biz/teacher/course/teacherWares`,
        {
          courseId: this.currentCourseID,
          firstSubjectId: this.firstSubjectId,
          name: this.courseWareName,
          secondSubjectId: this.secondSubjectId
        },
        e => {
          if (e.code == 200) {
            this.teacherWareList = e.rows;
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 搜索课件
    searchCourseWare() {
      this.getTeacherWares();
    },
    // 关联课件
    linkCourseWare() {
      if (this.teacherWareIds.length > 0) {
        this.post(
          '/biz/teacher/course/joinWares',
          { courseId: this.currentCourseID, teacherWareIds: this.teacherWareIds },
          e => {
            if (e.code == 200) {
              this.$.ui.toast('课件关联成功');
              setTimeout(() => {
                this.showLink = false;
                this.getCourseWares();
                // 成功后清空数组
                this.teacherWareIds = [];
              }, 1000);
            } else {
              this.$.ui.toast(e.msg);
            }
          }
        );
      } else {
        this.$.ui.toast('请选择关联课件');
      }
    },
    // 关联单个课件
    linkSingle(item) {
      let index = this.teacherWareIds.indexOf(item.id);
      if (index == -1) {
        this.teacherWareIds.push(item.id);
      }
      // 执行关联
      this.linkCourseWare();
    },
    // 取消关联课件
    removeCouseWare(id, type) {
      this.post(`/biz/teacher/course/remove/${id}`, {}, e => {
        if (e.code == 200) {
          this.$.ui.toast('课件取消关联成功');
          setTimeout(() => {
            if (type == 0) {
              this.getTeacherWares();
            } else if (type == 1) {
              this.getCourseWares();
            }
          }, 1000);
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    handleSelectionChange(val) {
      this.teacherWareIds = val;
      this.teacherWareIds = this.teacherWareIds.map(item => {
        return item.id;
      });
      console.log('val', val);
      console.log('this.teacherWareIds', this.teacherWareIds);
    },
    // 进入直播间
    toLive(item) {
		this.$.ui.toast('下载APP开始直播');
      return;
	  if (item.courseMode == 'o2o') {
        this.showO2O = true;
      } else if (item.courseMode == 'gkk') {
        this.showDbk = true;
      } else if (item.courseMode == 'lvb') {
        this.showXbk = true;
      }
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
      this.stopMove();
      this.lessonStatus = item.lessonStatus;
      this.lessonId = item.lessonId;
    },
    // 点击回看按钮
    toReview(item) {
      this.showVideo = true;
      setTimeout(() => {
        document.getElementById('player-container').innerHTML = '';
        this.player = new TcPlayer('player-container', {
          mp4: item.fileUrl,
          // RTMP:`http://1305813075.vod2.myqcloud.com/live/${item.matUrl}`,
          autoplay: true, // iOS下safari浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
          poster: item.coverUrl,
          width: '100%', // 视频的显示宽度，请尽量使用视频分辨率宽度
          height: '100%' // 视频的显示高度，请尽量使用视频分辨率高度
        });
      }, 100);
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    }
  },
  components: {
    xbkRoom: xbkRoom,
    dbkRoom: dbkRoom,
    o2oRoom: o2oRoom
  }
};
</script>

<style lang="less">
.liveRoom {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
}
.el-dialog {
  border-radius: 10px;
}
.dialog-container {
  /deep/ .el-dialog {
    border-radius: 10px !important;
  }
  .el-dialog__header {
    line-height: 30px;
    height: 30px;
    text-align: center;
    padding: 5px;
    background: #f5f5f7;
    border-radius: 10px 10px 0 0;
    .el-dialog__title {
      height: 20px;
      font-size: 14px;
      font-family: SF Pro Text;
      font-weight: 500;
      line-height: 20px;
      color: #45494d;
    }
  }
  .el-dialog__body {
    padding: unset;
    background: #ffffff;
    border-radius: 10px;
  }
  .el-dialog__body {
    border-radius: 10px;
  }
  .el-dialog__headerbtn {
    top: 15px;
  }
  .el-table {
    border-radius: 0 0 10px 10px;
    padding-left: 20px;
  }
}
</style>
